import React from 'react'
import { graphql } from 'gatsby'

import Accordion from 'components/Accordion'

import { Section, Container, Row, Column, Text, Box, Heading } from 'components'

import Layout from 'components/Layout'

import Categories from '../components/Categories'

import Sidebar from 'screens/Guide/components/Sidebar'
import NoResults from 'screens/Guide/components/NoResults'

import { useLocation } from 'context/location'

const FAQ = ({ data }) => {
  const {
    currentCategory: {
      slug: categorySlug,
      title: categoryTitle,
      name: categoryName,
      id: categoryId,
      description: { description: categoryDescription },
    },
    categories: { nodes: categories },
    faqs: { nodes: faqs },
  } = data

  const { countryCode } = useLocation()

  /**
   * Logic to filter out FAQs based on country:
   * 1. If there's no country specified in FAQ, show it
   * 2. Otherwise, if the app's country is DE or AT then show the FAQ if it's for that specific country
   * 3. Otherwise, if the app's country is different than DE or AT, only show the FAQ if for AT (as we show the AT faqs for any other country)
   *
   * Note: we don't show all DE and AT FAQs for countries other than DE or AT because some of the FAQs might be semi duplicates with different wording (specific for AT & DE)
   */
  const countrySpecificFAQs = faqs.filter(({ country: faqCountry }) => {
    if (faqCountry === null) {
      return true
    } else {
      if (countryCode === 'DE' || countryCode === 'AT') {
        return faqCountry === countryCode
      } else {
        return faqCountry === 'AT'
      }
    }
  })

  return (
    <Layout
      seo={{
        title: categoryTitle,
        description: categoryDescription,
      }}
    >
      <Section spacing="small">
        <Container>
          <Row flexWrap="wrap">
            <Column size={[1, 1, 7 / 12, 8 / 12]}>
              <Heading.H1
                sx={{
                  mt: [4, 0],
                  mb: 4,
                  textAlign: ['center', 'left'],
                  display: ['flex', 'block'],
                  flexDirection: ['column', 'unset'],
                  whiteSpace: ['initial', 'pre-wrap'],
                }}
              >
                Fragen und Antworten zum Teilverkauf
              </Heading.H1>
              <Box sx={{ display: 'flex' }}>
                <Text sx={{ fontSize: 0, ml: 1 }}>Häufige Fragen</Text>
                <Text variant="muted" sx={{ ml: 1 }}>
                  / {categoryName}
                </Text>
              </Box>

              <Box mt={3} />

              <Categories
                categories={categories}
                selectedCategoryId={categoryId}
              />

              <Box mt={6} />

              {faqs.length === 0 ? (
                <NoResults />
              ) : (
                <>
                  <Accordion
                    questions={countrySpecificFAQs}
                    slug={categorySlug}
                  />
                </>
              )}
            </Column>
            <Column
              id="StickySidebarBottomBoundary"
              size={[1, 1, 5 / 12, 4 / 12]}
            >
              <Sidebar bottomBoundarySelector="#StickySidebarBottomBoundary"></Sidebar>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default FAQ

export const query = graphql`
  query ($id: String, $slug: String) {
    currentCategory: contentfulFaqCategory(id: { eq: $id }) {
      ...Category
    }
    categories: allContentfulFaqCategory(
      filter: { node_locale: { eq: "de" } }
      sort: { fields: faq___createdAt, order: DESC }
    ) {
      nodes {
        ...Category
      }
    }
    faqs: allContentfulFaq(
      filter: { category: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: orderNumberLanding, order: DESC }
    ) {
      nodes {
        id
        country
        question
        answer {
          json
        }
      }
    }
  }
`
