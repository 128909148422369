import React from 'react'

import { Box, Heading, Text, Space } from 'components'

const NoResults = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 12,
        bg: 'colorBlue5',
        my: 6,
      }}
    >
      <Space mb={4}>
        <Box
          as="svg"
          viewBox="0 0 65 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{ color: 'colorBlue10', width: 65, height: 65 }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.3666 42.6333L58.3666 54.6333C59.4333 55.7 59.4333 57.3 58.3666 58.3666C57.8333 58.9 57.0333 59.1666 56.4999 59.1666C55.9666 59.1666 55.1666 58.9 54.6333 58.3666L42.6333 46.3666C38.6333 49.3 33.8333 51.1666 28.4999 51.1666C15.9666 51.1666 5.83325 41.0333 5.83325 28.5C5.83325 15.9666 15.9666 5.83331 28.4999 5.83331C41.0333 5.83331 51.1666 15.9666 51.1666 28.5C51.1666 33.8333 49.2999 38.9 46.3666 42.6333ZM28.4999 11.1666C18.8999 11.1666 11.1666 18.9 11.1666 28.5C11.1666 38.1 18.8999 45.8333 28.4999 45.8333C33.2999 45.8333 37.5666 43.9666 40.7666 40.7666C43.9666 37.5666 45.8333 33.3 45.8333 28.5C45.8333 18.9 38.0999 11.1666 28.4999 11.1666Z"
            fill="currentColor"
          />
        </Box>

        <Heading.H2 as="h3">Keine Ergebnisse.</Heading.H2>
        <Text>Tut uns leid, wir konnten nichts für Sie finden.</Text>
      </Space>
    </Box>
  )
}

export default NoResults
