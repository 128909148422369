import React from 'react'
import { graphql } from 'gatsby'

import ButtonBar from 'components/ButtonBar'

import { getPath } from '../utils'

const Categories = ({ categories = [], selectedCategoryId = null }) => {
  const computedAudiences = [
    // { id: 'all', name: 'Alle', slug: null },
    ...categories,
  ].map(({ id, name, slug }) => ({
    id,
    to: getPath({ category: slug }),
    children: name,
  }))

  return (
    <ButtonBar
      buttons={computedAudiences}
      selectedId={selectedCategoryId}
      minWidth={460}
    />
  )
}

export default Categories

export const query = graphql`
  fragment Category on ContentfulFaqCategory {
    id
    slug
    name
    title
    description {
      description
    }
  }
`
